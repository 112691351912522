import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createWithdrawRequest } from "../../actions/transactionAction";
import { useSnackbar } from "notistack";

const WithdrawModal = ({ isOpen, onClose, storeId, withdrawLimit }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount > withdrawLimit) {
      enqueueSnackbar("Please enter a valid amount", { variant: "error" });
    } else {
      const data = { storeId, amount: parseFloat(amount) }; // Prepare data for the action
      dispatch(createWithdrawRequest(data));
      enqueueSnackbar("Withdraw request submitted successfully", {
        variant: "success",
      });
      onClose(); // Close the modal after submission
      setAmount("");
    }
  };

  if (!isOpen) return null; // Don't render anything if the modal is not open

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-lg font-bold mb-4">Withdraw Amount</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            className="border border-gray-300 rounded-md p-2 w-full mb-4"
            required
          />
          <div className="flex justify-between">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-black rounded-md px-4 py-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white rounded-md px-4 py-2"
            >
              Withdraw
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WithdrawModal;
