import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStoreById,  
} from '../../actions/storeAction';
import { 
  approveWithdrawRequest,
  rejectWithdrawRequest,
 } from "../../actions/transactionAction";

const StoreWithdrawRequestModal = ({ isOpen, onClose, requestDetails }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { store } = useSelector((state) => state.store);

  console.log('store details:', store);

  useEffect(() => {
    if (requestDetails?.store_id) {
      dispatch(getStoreById(requestDetails.store_id));
    }
  }, [dispatch, requestDetails?.store_id]);

  if (!isOpen || !requestDetails) return null;

  const handleApprove = async () => {
    try {
      await dispatch(approveWithdrawRequest(requestDetails.id));
      enqueueSnackbar('Withdraw request approved successfully!', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(error || 'Failed to approve the request', { variant: 'error' });
    }
  };

  const handleReject = async () => {
    try {
      await dispatch(rejectWithdrawRequest(requestDetails.id));
      enqueueSnackbar('Withdraw request rejected successfully!', { variant: 'success' });
      onClose();
    } catch (error) {
      enqueueSnackbar(error || 'Failed to reject the request', { variant: 'error' });
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-lg font-bold mb-4">Withdraw Request Details</h2>

        <div>
          <div className="flex text-lg font-bold mb-4">Store Details</div>
          <div className="mt-4">
            <p>
              <span className="text-gray-500">Store ID:</span>{' '}
              <span className="font-semibold">{store.id}</span>
            </p>
            <p>
              <span className="text-gray-500">Store Name:</span>{' '}
              <span className="font-semibold">{store.name}</span>
            </p>
          </div>
        </div>
        <div className="mt-6">
          <div>
            <p>
              <span className="text-gray-500">Available Withdraw Limit:</span>{' '}
              <span className="font-semibold">{store.withdraw_limit}</span>
            </p>
            <p>
              <span className="text-gray-500">Requested Withdraw:</span>{' '}
              <span className="font-semibold">{requestDetails.amount}</span>
            </p>
          </div>
        </div>
        <div className="mt-6 flex justify-between">
          <button
            onClick={handleApprove}
            className="bg-green-500 text-white rounded-md px-4 py-2"
          >
            Approve
          </button>
          <button
            onClick={handleReject}
            className="bg-red-500 text-white rounded-md px-4 py-2"
          >
            Reject
          </button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 text-white rounded-md px-4 py-2"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default StoreWithdrawRequestModal;
