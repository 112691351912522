import { useEffect, useState, useRef } from "react";
import Sidebar from "./Sidebar/Sidebar";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import socket from "../../socket";
import { getToken, onMessage } from "firebase/messaging";
import { useSnackbar } from "notistack";
import axiosInstance from "../../utils/api";
import { messaging } from "../../firebase";

const Dashboard = ({ activeTab, children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [onMobile, setOnMobile] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isOpenNotfi, setIsOpenNotfi] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dashboardRef = useRef(null);
  const [notifications, setNotifications] = useState([]);

  const { user, isAuthenticated } = useSelector((state) => state.user);

  // Store FCM token
  const storeFcmToken = async (storeId, fcmToken) => {
    try {
        await axiosInstance.post("/stores/save-fcm-token", { store_id: storeId, fcm_token: fcmToken });
        console.log("FCM token saved successfully");
    } catch (error) {
        console.error("Error saving FCM token:", error);
    }
};

  useEffect(() => {
    const checkAndRequestPermission = async () => {
      if (Notification.permission === "default") {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.warn("Notification permission denied by the user.");
        }
      } else if (Notification.permission === "granted") {
        console.log("Notification permission already granted.");
      } else if (Notification.permission === "denied") {
        console.warn("Notification permission already denied. Please enable it manually in browser settings.");
      }
    };

    checkAndRequestPermission();
  }, []);

  useEffect(() => {
    const requestPermission = async () => {
        if (Notification.permission === "default") {
            const permission = await Notification.requestPermission();
            if (permission === "granted") {
                console.log("Notification permission granted.");
            } else {
                console.warn("Notification permission denied.");
            }
        }
    };
    requestPermission();
}, []);

useEffect(() => {
  const registerFCM = async () => {
    if (isAuthenticated && user && Notification.permission === "granted") {
      try {
        const fcmToken = await getToken(messaging, {
          vapidKey: "BNoQdLVxhqJkwCgVOLr5q0aTBFfDnan8ItL2wALCxn_4vKF8zr6igabtZgFAsNkTcw7Itbm34oX3HsTkGy_wdk8",
        });

        if (fcmToken) {
          console.log("FCM token:", fcmToken);
          await storeFcmToken(user.id, fcmToken); // Send token to backend
        } else {
          console.warn("No FCM token available. Requesting permission may help.");
        }
      } catch (error) {
        console.error("Error getting FCM token:", error);
        enqueueSnackbar("Failed to register for notifications. Please try again.", { variant: "error" });
      }
    } else {
      enqueueSnackbar("You must be logged in to receive notifications.", { variant: "warning" });
    }
  };
  registerFCM();
}, [user, isAuthenticated]);

useEffect(() => {
  onMessage(messaging, (payload) => {
      console.log("Foreground notification received:", payload);
      displayNotification(payload.notification);
  });

  socket.on("newOrder", (data) => {
      console.log("Socket.IO new order notification:", data);
      enqueueSnackbar(`New order: ${data.message}`, { variant: "info" });
  });

  return () => {
      socket.off("newOrder");
  };
}, []);

useEffect(() => {
  socket.on("newOrder", (data) => {
      console.log("New order notification received:", data);
      displayNotification(data);
  });

  return () => {
      socket.off("newOrder");
  };
}, [user]);

useEffect(() => {
  socket.on('orderItemStatusUpdated', (data) => {
      console.log('Order item status updated notification:', data);
      enqueueSnackbar(`Order ID: ${data.orderId} item statuses updated.`, {
          variant: 'info',
          autoHideDuration: 3000,
      });
  });

  return () => {
      socket.off('orderItemStatusUpdated');
  };
}, []);

const displayNotification = (notification) => {
  enqueueSnackbar(notification.message || 'New notification', {
    variant: 'info',
    autoHideDuration: 10000,
    action: (key) => (
      <CloseIcon
        onClick={() => { /* Close notification logic */ }}
        style={{ cursor: 'pointer', color: 'white' }}
      />
    ),
  });

  const audio = new Audio('/notification.wav');
  audio.play();

  setNotifications((prev) => [...prev, notification]);
};

useEffect(() => {
  const handleResize = () => {
    setOnMobile(window.innerWidth < 600);
  };

  window.addEventListener("resize", handleResize);

  handleResize();

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

const setTogglebar = () => {
  setToggle(!toggle);
};

useEffect(() => {
  if (onMobile) {
    setToggleSidebar(false);
  }
}, [onMobile]);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      dashboardRef.current &&
      !dashboardRef.current.contains(event.target)
    ) {
      setToggleSidebar(false);
    }
  };

  document.addEventListener("click", handleClickOutside);

  return () => {
    document.removeEventListener("click", handleClickOutside);
  };
}, []);

const handleDropdownToggle = () => {
  setIsDropdownOpen(!isDropdownOpen);
};

const handleLogout = () => {
  console.log("Logging out...");
};

const handleProfile = () => {
  navigate("/store/profile");
};

if (!isAuthenticated) {
  return <div>Loading...</div>;
}

return (
  <div ref={dashboardRef} className="flex bg-[#f9f9fb] min-h-screen sm:min-w-full">
    {!onMobile && !toggle && <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />}
    {toggleSidebar && (
      <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />
    )}
    <div
      className={`w-full min-h-screen ${
        toggle ? "" : "lg:ml-[18rem] sm:ml-48 md:ml-[10rem] sm:w-4/5"
      } `}
    >
      {!onMobile && (
        <div className="w-full bg-white shadow-md lg:w-full">
          <div className="flex justify-between items-center p-2 border-b border-gray-300">
            <div className="flex items-center gap-4 text-gray-500">
              <button className="pl-4" onClick={setTogglebar}>
                <MenuIcon />
              </button>
              <span className="mt-1 text-lg">Overview</span>
            </div>
            <div className="pr-4 cursor-pointer">
              {user && (
                <div className="relative">
                  <button
                    className="flex items-center space-x-2 bg-white rounded-full p-2 focus:outline-none hover:bg-gray-200"
                    onClick={handleDropdownToggle}
                  >
                    <div className="relative">
                      <img
                        src={user.owner_photo}
                        className="w-10 h-10 rounded-full object-cover shadow-md"
                        alt="User Avatar"
                      />
                      <span className="absolute right-0 bottom-0 w-3 h-3 rounded-full bg-green-500 border-2 border-white"></span>
                    </div>
                    <span className="text-gray-700">{user.name}</span>
                    <span className="ml-2">&#9662;</span>
                  </button>
                  {isDropdownOpen && (
                    <div className="absolute right-0 mt-3 bg-white border-b border-gray-300 rounded-md shadow-lg z-10 w-60">
                      <div className="flex items-center px-4 py-2 border-b border-gray-300">
                        <img
                          src={user.owner_photo}
                          className="w-8 h-8 rounded-full object-cover shadow-md"
                          alt="User Avatar"
                        />
                        <span className="ml-2 text-gray-700">{user.name}</span>
                      </div>
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={handleProfile}
                      >
                        Profile
                      </button>
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col gap-6 p-4 pb-6 overflow-hidden">
        <div className="flex justify-between">
          <div>
            <button
              onClick={() => setToggleSidebar(true)}
              className="sm:hidden bg-gray-700 w-10 h-10 rounded-full shadow text-white flex items-center justify-center"
            >
              <MenuIcon />
            </button>
          </div>
          <div className="flex gap-2 sm:hidden">
            <div className="flex flex-col gap-6 p-4 pb-6 overflow-hidden">
              <div className="flex justify-end">
                <NotificationsIcon
                  className="cursor-pointer"
                  onClick={() => setIsOpenNotfi(!isOpenNotfi)}
                />
              </div>
              {isOpenNotfi && (
                <div className="absolute right-0 mt-3 bg-white border border-gray-300 rounded-md shadow-lg z-10 w-60">
                  {notifications.length > 0 ? (
                    notifications.map((notification, index) => (
                      <div key={index} className="px-4 py-2 border-b border-gray-300">
                        <span className="text-gray-700">{notification.title}</span>
                        <span className="text-sm text-gray-500">{notification.body}</span>
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-2">No notifications</div>
                  )}
                </div>
              )}
            </div>
            <div className="relative">
              <img
                src={user.owner_photo}
                className="w-10 h-10 rounded-full object-cover shadow-md cursor-pointer"
                alt="User Avatar"
                onClick={handleDropdownToggle}
              />
              <span className="absolute right-0 bottom-0 w-3 h-3 rounded-full bg-green-500 border-2 border-white"></span>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-3 bg-white border-b border-gray-300 rounded-md shadow-lg z-10 w-60">
                  <div className="flex items-center px-4 py-2 border-b border-gray-300">
                    <img
                      src={user.owner_photo}
                      className="w-8 h-8 rounded-full object-cover shadow-md"
                      alt="User Avatar"
                    />
                    <span className="ml-2 text-gray-700">{user.name}</span>
                  </div>
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleProfile}
                  >
                    Profile
                  </button>
                  <button
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isOpenNotfi ? (
          <div className="flex flex-col rounded-lg">
            <span className="text-xl font-semibold text-gray-700">
              Welcome, {user.name}
            </span>
            <span className="text-sm text-gray-500 mt-1">
              Monitor your business analytics and statistics.
            </span>
          </div>
        ) : (
          <div>Notifications will be here</div>
        )}
      </div>
      {children}
    </div>
  </div>
);
};

export default Dashboard;
