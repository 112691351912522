import React from "react";
import { Link, useNavigate } from "react-router-dom";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReviewsIcon from "@mui/icons-material/Reviews";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import KeyboardDoubleArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardDoubleArrowLeftTwoTone';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { logoutUser } from "../../../actions/userAction";
import logo from "../../../assets/images/logo1.png";

const navMenu = [
  {
    icon: <EqualizerIcon />,
    label: "Dashboard",
    path: "/store",
  },
  {
    icon: <LocalMallIcon />,
    label: "POS",
    path: "/store/pos",
  },
  {
    icon: <InventoryIcon />,
    label: "Products",
    path: "/store/products",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Orders",
    path: "/store/orders",
  },
  {
    icon: <ShoppingBagIcon />,
    label: "Transactions",
    path: "/store/transactions",
  },
  {
    icon: <ReviewsIcon />,
    label: "Reviews",
    path: "/store/reviews",
  },
  {
    icon: <AccountBoxIcon />,
    label: "Profile",
    path: "/store/profile",
  },
  {
    icon: <AssuredWorkloadIcon />,
    label: "Bank Information",
    path: "/store/bank-info",
  },
  {
    icon: <LogoutIcon />,
    label: "Logout",
    path: "#",
  },
];

const Sidebar = ({ activeTab, setToggleSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);

  const handleLogout = () => {
    dispatch(logoutUser());
    enqueueSnackbar("Successfully logged out", { variant: "success" });
    navigate("/store/login");
  };

  return (
    <div
      className="fixed top-0 left-0 h-screen bg-[#073b74] shadow-lg w-[17rem] z-20"
      style={{ height: "100%", overflow: "auto" }}
    >
      <div className="flex justify-between items-center p-4 border-b border-gray-300">
        <img src={logo} alt="Logo" className="w-25 h-20" />
        {/* <button
          onClick={() => {
            console.log("Close button clicked");
            setToggleSidebar(false);
          }}
          className="text-white hover:text-gray-700"
        >
          <KeyboardDoubleArrowLeftTwoToneIcon />
        </button> */}
      </div>
      <ul className="mt-4">
      {navMenu.map((menuItem, index) => (
  <li key={index} className="px-4 py-2">
    {menuItem.path === "#" ? (
      <button
        className={`w-full text-white flex items-center p-2 rounded-lg text-gray-700 hover:bg-primary-opacity hover:text-green-200 ${
          activeTab === menuItem.label ? "bg-gray-200" : ""
        }`}
        onClick={handleLogout}
      >
        {menuItem.icon}
        <span className="ml-3">{menuItem.label}</span>
      </button>
    ) : (
      <Link
        to={menuItem.path}
        className={`w-full flex text-white items-center p-2 rounded-lg text-gray-700 hover:bg-primary-opacity hover:text-green-200 ${
          activeTab === menuItem.label ? "bg-gray-200 " : ""
        }`}
      >
        {menuItem.icon}
        <span className="ml-3">{menuItem.label}</span>
      </Link>
    )}
  </li>
))}
      </ul>
    </div>
  );
};

export default Sidebar;
