import { useSnackbar } from "notistack";
import { useEffect, useState, useCallback  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import MopedIcon from "@mui/icons-material/Moped";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { removeItem, addItemsToCart } from "../../actions/cartAction";
import { ADMIN_PRODUCT_API } from "../../utils/constants";
import Cart from "../Cart/Cart";
import DeliveryEdit from "../Cart/DeliveryEdit";
import image1 from "../../assets/images/ProductDetails1.png";
import image2 from "../../assets/images/ProductDetails2.jpg";
import image3 from "../../assets/images/ProductDetails3.jpg";
import { TbShare3 } from "react-icons/tb";
import CategoryProduct from "../Home/CategoryProduct";
import { NextBtn, PreviousBtn } from "../Home/Banner/Banner";
import Slider from "react-slick";
import { getSubCategoryId } from "../../actions/categoryAction";
import Product from "../Products/Product";

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  initialSlide: 1,
  swipe: true,
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
      },
    },
  ],
};

export const bannerSettings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000, // time in milliseconds between each slide
  speed: 500, // time in milliseconds for each slide transition
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  swipe: true,
};

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();

  const Product_id = params.productId;

  console.log("Product id using use params");

  // reviews toggle

  const [viewProduct, setViewProduct] = useState({});

  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  const { cartItems } = useSelector((state) => state.cart);

  console.log("cart items:  ", cartItems);

  const [count, setCount] = useState(1);
  const [showCounter, setShowCounter] = useState(false);
  const [subCategory, setSubCategory] = useState();
  const [subsubCategory, setSubSubCategory] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [productCounts, setProductCounts] = useState({});
  const [selectedVariablePrice, setSelectedVariablePrice] = useState(null);
  const [weightQuantities, setWeightQuantities] = useState({});
  const [activeImage, setActiveImage] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState(null);

  const { subCategoryId } = useSelector((state) => state.subCategoryId);

  console.log("sub category name:", subCategoryName);
  console.log("sub category id:", subCategoryId);

  useEffect(() => {
    dispatch(getSubCategoryId(subCategoryName));
  }, [subCategoryName]);

  useEffect(() => {
    if (viewProduct && viewProduct.Prodouct_img_0) {
      setActiveImage(viewProduct.Prodouct_img_0);
    }
  }, [viewProduct]);

  useEffect(() => {
    if (viewProduct && viewProduct.Sub_Categories) {
      setSubCategoryName(viewProduct.Sub_Categories);
    }
  }, [viewProduct]);

  const images = [
    viewProduct.Prodouct_img_0,
    viewProduct.Prodouct_img_1,
    viewProduct.Prodouct_img_2,
    viewProduct.Prodouct_img_3,
    viewProduct.Prodouct_img_4,
  ];

  const currentUrl = window.location.pathname;
  const product_id = currentUrl
    .substring(currentUrl.lastIndexOf("/") + 2)
    .trim();

  console.log("selected variable prices:", selectedVariablePrice);

  const handleVariablePriceClick = (price) => {
    console.log("Product_id:", product_id);
    console.log("Weight:", price.Weight);
    console.log("cartItems:", cartItems);
    console.log("delivery option:", price.delivery_option);

    setSelectedVariablePrice(price);
  };

  // Set default value for selectedVariablePrice
  useEffect(() => {
    if (viewProduct.variablePrices && viewProduct.variablePrices.length > 0) {
      setSelectedVariablePrice(viewProduct.variablePrices[0]);
    } else {
      setSelectedVariablePrice(viewProduct);
    }
  }, [viewProduct]);

  useEffect(() => {
    if (selectedVariablePrice) {
      const existingItem = cartItems.find((item) => {
        const productMatch = String(item.product_id) === String(product_id); // Ensure type consistency
        const weightMatch = item.weight === selectedVariablePrice.Weight;
        return productMatch && weightMatch;
      });

      if (existingItem) {
        console.log("Quantity found:", existingItem.quantity);

        // Update the weightQuantities state for the selected weight
        setWeightQuantities((prevQuantities) => ({
          ...prevQuantities,
          [selectedVariablePrice.Weight]: existingItem.quantity,
        }));

        setCount(existingItem.quantity || 0); // Update the counter with the found quantity
      } else {
        console.log("Quantity not found");
        setCount(0);

        // If no quantity found, set to 0 for that weight in weightQuantities state
        setWeightQuantities((prevQuantities) => ({
          ...prevQuantities,
          [selectedVariablePrice.Weight]: 0,
        }));
      }
    }
  }, [cartItems, product_id, selectedVariablePrice]);

  const handleAddToCart = (
    productId,
    quantity,
    weight,
    sell_price,
    MRP,
    offer,
    delivery_option
  ) => {
    dispatch(
      addItemsToCart(
        productId,
        quantity,
        weight,
        sell_price,
        MRP,
        offer,
        delivery_option
      )
    );
  };

  const handleIncrease = (productId, weight) => {
    dispatch(addItemsToCart(productId, 1, weight));
  };

  const handleDecrease = (productId, weight) => {
    if (count > 1) {
      dispatch(addItemsToCart(productId, -1, weight));
    } else {
      dispatch(removeItem(productId, weight));
      setShowCounter(false);
    }
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(viewProduct.Product_id, count)); // Make sure this uses viewProduct.Product_id
    enqueueSnackbar("Product Added To Cart", { variant: "success" });
    setShowCounter(true);
  };

  const itemInCart = cartItems.some((i) => i.product === product_id);

  const goToCart = () => {
    navigate("/cart");
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Fetch product data
        const response = await fetch(`${ADMIN_PRODUCT_API}/${product_id}`);
        const productData = await response.json();

        setViewProduct(productData);
        setSubCategory(productData.Sub_Categories);
        setSubSubCategory(productData.Sub_Sub_Categories);

        // Get cart items from local storage and check if the product exists in the cart
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const item = cartItems.find(
          (item) => item.product_id === productData.Product_id
        );

        if (item) {
          setShowCounter(true);
          setCount(item.quantity);
        } else {
          setShowCounter(false);
        }

        // Fetch the price based on storeId if available
        const storedStoreId = localStorage.getItem("StoreId");
        const storeId = storedStoreId
          ? JSON.parse(storedStoreId).store_id
          : null;

        const priceUrl = storeId
          ? `${ADMIN_PRODUCT_API}/${product_id}/price?storeId=${storeId}`
          : `${ADMIN_PRODUCT_API}/${product_id}`;

        const priceResponse = await fetch(priceUrl);
        const priceData = await priceResponse.json();

        // Log the price data to debug
        console.log("Price data:", priceData);

        // Set product price and related information
        if (priceData.length > 0) {
          setViewProduct((prevState) => ({
            ...prevState,
            MRP: priceData.MRP,
            offer: priceData.offer,
            you_save: priceData.offer,
            sell_price: priceData.sell_price,
            delivery_option: priceData[0]?.delivery_option,
          }));
        } else {
          // If price data from store not found, fallback to product MRP
          setViewProduct((prevState) => ({
            ...prevState,
            price: productData.MRP,
            offer: productData.offer,
            sell_price: productData.sell_price,
            you_save: productData.you_save,
            delivery_option: productData.delivery_option,
          }));
        }
      } catch (error) {
        console.error("Error fetching product or price data:", error);
      }
    };

    fetchProductData();
  }, [product_id]);

  console.log("view product data:", viewProduct);

  useEffect(() => {
    if (subCategory && subsubCategory && viewProduct.Product_id) {
      fetch(`${ADMIN_PRODUCT_API}`)
        .then((response) => response.json())
        .then((productData) => {
          if (Array.isArray(productData)) {
            const filtered = productData.filter((item) => {
              return (
                subsubCategory.includes(item.Sub_Sub_Categories) &&
                item.Product_id !== viewProduct.Product_id // Exclude the current product
              );
            });

            const limitedProducts = filtered.slice(0, 30);
            setFilteredData(limitedProducts);
          } else {
            console.error("Invalid response from API");
          }
        })
        .catch((error) =>
          console.error("Error fetching categorized data:", error)
        );
    }
  }, [subCategory, subsubCategory, viewProduct.Product_id]);

  console.log("Similar Product:", filteredData);

  const closeCartSidebar = () => {
    setIsCartOpen(false);
    document.body.style.overflow = "auto"; // Enable scrolling
  };

  const generateShareableLink = () => {
    const baseUrl = window.location.origin;
    const shareableLink = `${baseUrl}/product/:${product_id}`;
    console.log("Link:", shareableLink);
    return shareableLink;
  };

  const handleImageClick = (image) => {
    setActiveImage(image);
  };

  return (
    <>
      <div className="min-h-screen w-full pt-[6rem] hidden lg:block">
        <div className="flex items-stretch mt-4 lg:mx-28">
          <div className="w-[50%] h-full">
            <div className="h-[35rem] rounded-lg">
              <div className="flex justify-center w-full h-96">
                <div className="w-[450px] h-[450px] relative">
                  <img
                    className="w-full h-full pointer-events-none"
                    src={activeImage}
                    alt={viewProduct.name}
                  />
                </div>
              </div>
              <div className="flex justify-center mt-20">
                {images.map((image, index) => (
                  <div key={index} className="mr-4 border rounded-lg">
                    <img
                      className="w-20 h-20 cursor-pointer"
                      src={image}
                      alt={viewProduct.name}
                      onClick={() => handleImageClick(image)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-gray-200 h-px"></div>
            <div className="">
              <div className="text-gray-700 text-2xl font-semibold leading-8 mt-8 mb-4">
                Product Details
              </div>
              <div style={{ height: "max-content" }}>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Unit
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    {viewProduct.Weight}
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Country Of Origin
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    India
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Customer Care Details
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    Email: info@vivimart.com
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Return Policy
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    The product is non-returnable. For a damaged, defective,
                    expired or incorrect item, you can request a replacement
                    within 24 hours of delivery. In case of an incorrect item,
                    you may raise a replacement or return request only if the
                    item is sealed/ unopened/ unused and in original condition.
                  </span>
                </div>
                <div className="my-4">
                  <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                    Disclaimer
                  </p>
                  <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                    Every effort is made to maintain accuracy of all
                    information. However, actual product packaging and materials
                    may contain more and/or different information. It is
                    recommended not to solely rely on the information presented.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pl-12 w-[50%] sticky top-20 bottom-0 self-start">
            {viewProduct.Categories && (
              <div className="flex flex-col">
                <div className="flex justify-start items-center text-sm text-[rgba(13,19,0,var(--text-opacity))]">
                  <div className="flex justify-start items-center">
                    <a className="flex items-center" href="/">
                      <span className="text-xs text-darkOnyx-800">Home</span>
                    </a>
                    <span className="px-2 text-xs">/</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <a
                      className="flex items-center"
                      href={`/products?sub_category_id=${subCategoryId}`}
                    >
                      <span className="text-xs text-darkOnyx-800 truncate">
                        {viewProduct.Sub_Categories}
                      </span>
                    </a>
                    <span className="px-2 text-xs">/</span>
                  </div>
                  <div className="flex justify-start items-center">
                    <span
                      className="text-sm text-darkOnyx-800 truncate w-[8rem]"
                      title={viewProduct.Product_name}
                    >
                      <span className="text-xs text-darkOnyx-800 truncate">
                        {viewProduct.Product_name}
                      </span>
                    </span>
                  </div>
                </div>

                <h2 className="text-black text-[24px] leading-8 font-bold mt-4">
                  {viewProduct.Product_name}
                </h2>
                <Link to={`/brand-products/${viewProduct.Brand_name}`}>
                  <div className="flex items-center text-primary-blue text-[18px] mt-2 font-semibold">
                    <div>View all by {viewProduct.Brand_name}</div>
                    <KeyboardArrowRightIcon />
                  </div>
                </Link>
                <div className="flex flex-col gap-4">
                  {/* <div className="inline-flex w-[5rem] items-center rounded-md px-1 font-bold text-gray-800 bg-gray-200">
                <img
                  className="h-4 w-4"
                  src="https://cdn.grofers.com/assets/eta-icons/15-mins.png"
                  alt="clock"
                />
                <span className="text-sm">13 MINS</span>
              </div> */}
                  <div className="h-px mt-2 w-full bg-gray-300"></div>
                  {selectedVariablePrice && (
                    <div className="flex justify-between items-start ">
                      <div className="flex flex-col justify-left items-left">
                        <div className="flex items-center text-sm text-gray-500 line-through leading-md p-0">
                          MRP: ₹{Math.ceil(selectedVariablePrice.MRP)}
                        </div>
                        <div className="flex items-center text-lg">
                          <span className="font-semibold">
                            Price: ₹
                            {Math.ceil(selectedVariablePrice.sell_price)}
                          </span>
                        </div>
                        <div className="flex items-center text-gray-500 text-[12px] mb-4">
                          <span className="font-semibold">
                            (Inclusive of all taxes)
                          </span>
                        </div>
                        <div className="flex items-center text-[14px] text-white mb-1 leading-md p-0">
                          <span
                            className="border px-2 py-1 rounded-lg border-green-700 shadow-2xl"
                            style={{ backgroundColor: "rgb(49, 134, 22)" }}
                          >
                            {selectedVariablePrice.you_save
                              ? `You Save: ₹${Math.ceil(
                                  selectedVariablePrice.you_save
                                )}`
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        {weightQuantities[selectedVariablePrice?.Weight] > 0 ? (
                          <div className="flex items-center">
                            <button
                              className="cursor-pointer w-8 h-10 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l border-r"
                              onClick={() =>
                                handleDecrease(
                                  viewProduct.Product_id,
                                  selectedVariablePrice?.Weight
                                )
                              }
                              style={{ backgroundColor: "rgb(49, 134, 22)" }}
                            >
                              -
                            </button>
                            <div
                              style={{ backgroundColor: "rgb(49, 134, 22)" }}
                              className="h-10 w-10 text-white font-semibold text -sm leading-5 tracking-wide bg-green-50 flex justify-center items-center p-2"
                            >
                              {weightQuantities[selectedVariablePrice?.Weight]}
                            </div>
                            <button
                              className="cursor-pointer w-8 h-10 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r border-l"
                              onClick={() =>
                                handleIncrease(
                                  viewProduct.Product_id,
                                  selectedVariablePrice?.Weight
                                )
                              }
                              style={{ backgroundColor: "rgb(49, 134, 22)" }}
                            >
                              +
                            </button>
                          </div>
                        ) : (
                          <button
                            className="cursor-pointer w-20 border border-green-500 h-10 text-green-500 font-semibold text-sm leading-5 tracking-wide uppercase bg-green-50 rounded flex justify-center items-center"
                            onClick={() =>
                              handleAddToCart(
                                viewProduct.Product_id,
                                1,
                                selectedVariablePrice?.Weight,
                                selectedVariablePrice?.sell_price,
                                selectedVariablePrice?.MRP,
                                selectedVariablePrice?.offer,
                                viewProduct?.delivery_option
                              )
                            }
                          >
                            ADD
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="flex text-gray-500 gap-2 text-sm">
                    <MopedIcon
                      className={
                        viewProduct.delivery_option.includes("Fast Delivery")
                          ? "text-yellow-500"
                          : "text-red-500"
                      }
                    />
                    <span
                      className={
                        viewProduct.delivery_option.includes("Fast Delivery")
                          ? "text-yellow-500"
                          : "text-red-500"
                      }
                    >
                      {viewProduct.delivery_option}
                    </span>
                  </div>
                  <div className="mb-2 font-bold text-base leading-relaxed ">
                    <span>Pack Sizes:</span>
                    {viewProduct.variablePrices &&
                    viewProduct.variablePrices.length > 0 ? (
                      <div className="flex flex-wrap justify-start mt-2">
                        {viewProduct.variablePrices.map((price, index) => (
                          <div
                            key={index}
                            className={`cursor-pointer rounded-md w-36 max-w-32 m-2 overflow-hidden mb-4 text-center hover:shadow-lg border flex bg-white h-26 ${
                              selectedVariablePrice === price
                                ? "border-blue-500"
                                : "border-gray-300"
                            }`}
                            onClick={() => handleVariablePriceClick(price)}
                          >
                            <div className="flex flex-col items-start bg-blue-100 justify-start w-full p-2">
                              <div className="flex justify-start w-full h-full">
                                <div className="flex w-full items-center flex-col">
                                  <div className="w-full">
                                    <span className="w-auto overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-[14px] leading-4 text-gray-800">
                                      {price.Weight}
                                    </span>
                                    <span
                                      className="clamp-1 text-customGray text-sm pt-1.5 leading-5"
                                      style={{
                                        "--text-opacity": 1,
                                        width: "10rem",
                                      }}
                                    />
                                    <span
                                      className="justify-start text-green-600 rounded-lg text-xs leading-5 px-1.5 py-0.5 mt-1 ml-2"
                                      style={{
                                        backgroundColor:
                                          "rgba(241, 248, 230, 1)",
                                        color: "rgba(71, 111, 0, 1)",
                                        fontSize: "0.75rem",
                                        lineHeight: "1.1875",
                                      }}
                                    >
                                      {Math.ceil(price.offer)}% OFF
                                    </span>
                                  </div>
                                  <div className="pt-1.5 flex w-full items-center">
                                    <span className="justify-start text-[16px] leading-5 mr-2">
                                      {" "}
                                      {/* Added margin-right for spacing */}₹
                                      {price.sell_price}
                                    </span>
                                    <span
                                      style={{
                                        "--text-opacity": 1,
                                        color:
                                          "rgba(144, 144, 144, var(--text-opacity))",
                                      }}
                                      className="text-sm line-through" // Optional: Add a class for consistent styling
                                    >
                                      ₹{Math.ceil(price.MRP)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div
                        className={`justify-between cursor-pointer rounded-md w-36 max-w-32 mt-2 overflow-hidden mb-2.5 hover:shadow-1 border flex bg-white min-h-16 border-blue-500`}
                        onClick={() => handleVariablePriceClick(viewProduct)}
                      >
                        <div className="flex flex-col items-start bg-blue-100 justify-start w-full p-2">
                          <div className="flex justify-start w-full h-full">
                            <div className="flex w-full items-center flex-col">
                              <div className="w-full">
                                <span className="w-auto overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-[14px] leading-4 text-gray-800">
                                  {viewProduct.Weight}
                                </span>
                                <span
                                  className="clamp-1 text-customGray text-sm pt-1.5 leading-5"
                                  style={{
                                    "--text-opacity": 1,
                                    width: "10rem",
                                  }}
                                />
                                {
                                  viewProduct.offer > 0 ? (
                                    <span
                                  className="justify-start text-green-600 rounded-lg text-xs leading-5 px-1.5 py-0.5 mt-1 ml-2"
                                  style={{
                                    backgroundColor: "rgba(241, 248, 230, 1)",
                                    color: "rgba(71, 111, 0, 1)",
                                    fontSize: "0.75rem",
                                    lineHeight: "1.1875",
                                  }}
                                >
                                  {Math.ceil(viewProduct.offer)}% OFF
                                </span>
                                ) : null}                               
                              </div>
                              <div className="pt-1.5 flex w-full items-center">
                                <span className="justify-start text-[16px] leading-5 mr-2">
                                  {" "}
                                  {/* Added margin-right for spacing */}₹
                                  {Math.ceil(viewProduct.sell_price)}
                                </span>
                                <span
                                  style={{
                                    "--text-opacity": 1,
                                    color:
                                      "rgba(144, 144, 144, var(--text-opacity))",
                                  }}
                                  className="text-sm line-through" // Optional: Add a class for consistent styling
                                >
                                  ₹{Math.ceil(viewProduct.MRP)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!viewProduct.Categories && <div className="h-[20rem]"></div>}
            <div className="inline-block ">
              <div className="pt-0 pb-0 text-base mt-6 mb-4 text-gray-700 font-semibold font-okra-bold">
                Why shop from vivimart?
              </div>
              <div className="flex flex-col ">
                <div className="flex gap-8 mb-4">
                  <div className="h-14 w-14 cursor-default rounded-none">
                    <img
                      className="rounded-none object-fill cursor-default"
                      src={image1}
                      alt="Superfast Delivery"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-800 text-sm leading-4 mt-0 capitalize">
                      Superfast Delivery
                    </span>
                    <p className="text-gray-600 mt-2 text-sm leading-4 overflow-wrap-break">
                      Get your order delivered to your doorstep at the earliest
                      from dark stores near you.
                    </p>
                  </div>
                </div>
                <div className="flex gap-8 mb-4">
                  <div className="h-14 w-14 cursor-default rounded-none">
                    <img
                      className="rounded-none object-fill cursor-default"
                      src={image2}
                      alt="Best Prices & Offer"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-800 text-sm leading-4 mt-0 capitalize">
                      Best Prices & Offers
                    </span>
                    <p className="text-gray-600 mt-2 text-sm leading-4 overflow-wrap-break">
                      Best price destination with offers directly from the
                      manufacturers.
                    </p>
                  </div>
                </div>
                <div className="flex gap-8 mb-4">
                  <div className="h-14 w-14 cursor-default rounded-none">
                    <img
                      className="rounded-none object-fill cursor-default"
                      src={image3}
                      alt="Wide Assortment"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-800 text-sm leading-4 mt-0 capitalize">
                      Wide Assortment
                    </span>
                    <p className="text-gray-600 mt-2 text-sm leading-4 overflow-wrap-break">
                      Choose from 5000+ products across food, personal care,
                      household & other categories.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 w-auto lg:mx-28">
          <div className="text-xl font-semibold mb-6">Similar Products</div>
          <div className="hidden lg:block">
            <div className="mt-6 w-full">
              <Slider {...settings}>
                {filteredData.map((product, index) => (
                  <div className="flex flex-cols w-full items-center justify-center bg-white h-full overflow-x-auto hidden-scrollbar">
                    <div className="m-1 md:my-2 lg:my-2">
                      <CategoryProduct key={index} product={product} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view */}
      <div className="min-h-screen bg-white pt-[13rem] lg:hidden">
        <div className="bg-[var(--colors-white-050)] rounded-none ml-11 mr-11">
          <Slider {...bannerSettings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  className="w-full h-full"
                  src={image}
                  alt={viewProduct.name}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="px-4 mt-4">
          <div className="pt-3 pr-3 pb-4">
            <h2
              className="text-black text-2xl leading-8 font-bold m-0"
              style={{ fontSize: "19px" }}
            >
              {viewProduct.Product_name}
            </h2>
            <Link to={`/brand-products/${viewProduct.Brand_name}`}>
              <div className="flex items-center text-primary-blue text-[15px] mb-4">
                <div>View all by {viewProduct.Brand_name}</div>
                <KeyboardArrowRightIcon />
              </div>
            </Link>
            <div className="flex text-gray-500 gap-2 text-sm">
              <MopedIcon
                className={
                  viewProduct.delivery_option &&
                  viewProduct.delivery_option.includes("Fast Delivery")
                    ? "text-yellow-500"
                    : "text-red-500"
                }
              />
              <span
                className={
                  viewProduct.delivery_option &&
                  viewProduct.delivery_option.includes("Fast Delivery")
                    ? "text-yellow-500"
                    : "text-red-500"
                }
              >
                {viewProduct.delivery_option}
              </span>
            </div>
          </div>
          {selectedVariablePrice && (
            <div className="flex justify-between items-start ">
              <div className="flex flex-col justify-left items-left">
                <div className="flex items-center text-sm text-gray-500 line-through leading-md p-0">
                  MRP: ₹{Math.ceil(selectedVariablePrice.MRP)}
                </div>
                <div className="flex items-center text-lg mb-4">
                  <span className="font-semibold">
                    Price: ₹{Math.ceil(selectedVariablePrice.sell_price)}
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                {weightQuantities[selectedVariablePrice?.Weight] > 0 ? (
                  <div className="flex items-center">
                    <button
                      className="cursor-pointer w-8 h-10 bg-transparent text-white font-semibold text-sm leading-5 tracking-wide uppercase rounded-l border-r"
                      onClick={() =>
                        handleDecrease(
                          viewProduct.Product_id,
                          selectedVariablePrice?.Weight
                        )
                      }
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    >
                      -
                    </button>
                    <div
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                      className="h-10 w-10 text-white font-semibold text -sm leading-5 tracking-wide bg-green-50 flex justify-center items-center p-2"
                    >
                      {weightQuantities[selectedVariablePrice?.Weight]}
                    </div>
                    <button
                      className="cursor-pointer w-8 h-10 bg-transparent text-white font-semibold text-md leading-5 tracking-wide uppercase rounded-r border-l"
                      onClick={() =>
                        handleIncrease(
                          viewProduct.Product_id,
                          selectedVariablePrice?.Weight
                        )
                      }
                      style={{ backgroundColor: "rgb(49, 134, 22)" }}
                    >
                      +
                    </button>
                  </div>
                ) : (
                  <button
                    className="cursor-pointer w-20 border border-green-500 h-10 text-green-500 font-semibold text-sm leading-5 tracking-wide uppercase bg-green-50 rounded flex justify-center items-center"
                    onClick={() =>
                      handleAddToCart(
                        viewProduct.Product_id,
                        1,
                        selectedVariablePrice?.Weight,
                        selectedVariablePrice?.sell_price,
                        selectedVariablePrice?.MRP,
                        selectedVariablePrice?.offer,
                        viewProduct?.delivery_option
                      )
                    }
                  >
                    ADD
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mb-2 ml-2 mr-2 font-bold text-base leading-relaxed ">
          <span>Pack Sizes:</span>
          {viewProduct.variablePrices &&
          viewProduct.variablePrices.length > 0 ? (
            <div className="flex flex-wrap justify-start mt-2">
              {viewProduct.variablePrices.map((price, index) => (
                <div
                  key={index}
                  className={`cursor-pointer rounded-md w-38 m-2 overflow-hidden mb-4 text-center hover:shadow-lg border flex bg-white h-26 ${
                    selectedVariablePrice === price
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => handleVariablePriceClick(price)}
                >
                  <div className="flex flex-col items-start bg-blue-100 justify-start w-full p-2">
                    <div className="flex justify-start w-full h-full">
                      <div className="flex w-full items-center flex-col">
                        <div className="w-full">
                          <span className="w-auto overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-[14px] leading-4 text-gray-800">
                            {price.Weight}
                          </span>
                          <span
                            className="clamp-1 text-customGray text-sm pt-1.5 leading-5"
                            style={{
                              "--text-opacity": 1,
                              width: "10rem",
                            }}
                          />
                          <span
                            className="justify-start text-green-600 rounded-lg text-xs leading-5 px-1.5 py-0.5 mt-1 ml-2"
                            style={{
                              backgroundColor: "rgba(241, 248, 230, 1)",
                              color: "rgba(71, 111, 0, 1)",
                              fontSize: "0.75rem",
                              lineHeight: "1.1875",
                            }}
                          >
                            {Math.ceil(price.offer)}% OFF
                          </span>
                        </div>
                        <div className="pt-1.5 flex w-full items-center">
                          <span className="justify-start text-[16px] leading-5 mr-2">
                            {" "}
                            {/* Added margin-right for spacing */}₹
                            {price.sell_price}
                          </span>
                          <span
                            style={{
                              "--text-opacity": 1,
                              color: "rgba(144, 144, 144, var(--text-opacity))",
                            }}
                            className="text-sm line-through" // Optional: Add a class for consistent styling
                          >
                            ₹{Math.ceil(price.MRP)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div
              className={`justify-between cursor-pointer rounded-md w-[148px] mt-2 overflow-hidden mb-2.5 hover:shadow-1 border flex bg-white min-h-16 border-blue-500`}
              onClick={() => handleVariablePriceClick(viewProduct)}
            >
              <div className="flex flex-col items-start bg-blue-100 justify-start w-full max-w-40 p-2">
                <div className="flex justify-start w-full h-full">
                  <div className="flex w-full items-center flex-col">
                    <div className="w-full">
                      <span className="w-auto overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-[14px] leading-4 text-gray-800">
                        {viewProduct.Weight}
                      </span>
                      <span
                        className="clamp-1 text-customGray text-sm pt-1.5 leading-5"
                        style={{
                          "--text-opacity": 1,
                          width: "10rem",
                        }}
                      />
                      <span
                        className="justify-start text-green-600 rounded-lg text-xs leading-5 px-1.5 py-0.5 mt-1 ml-2"
                        style={{
                          backgroundColor: "rgba(241, 248, 230, 1)",
                          color: "rgba(71, 111, 0, 1)",
                          fontSize: "0.75rem",
                          lineHeight: "1.1875",
                        }}
                      >
                        {Math.ceil(viewProduct.offer)}% OFF
                      </span>
                    </div>
                    <div className="pt-1.5 flex w-full items-center">
                      <span className="justify-start text-[16px] leading-5 mr-2">
                        {" "}
                        {/* Added margin-right for spacing */}₹
                        {viewProduct.sell_price}
                      </span>
                      <span
                        style={{
                          "--text-opacity": 1,
                          color: "rgba(144, 144, 144, var(--text-opacity))",
                        }}
                        className="text-sm line-through" // Optional: Add a class for consistent styling
                      >
                        ₹{Math.ceil(viewProduct.MRP)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="pr-2 ml-2">
          <div className="text-gray-700 text-2xl font-semibold leading-8 mt-8 mb-4">
            Product Details
          </div>
          <div style={{ height: "max-content" }}>
            <div className="my-4">
              <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                Unit
              </p>
              <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                {viewProduct.Weight}
              </span>
            </div>
            <div className="my-4">
              <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                Country Of Origin
              </p>
              <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                India
              </span>
            </div>
            <div className="my-4">
              <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                Customer Care Details
              </p>
              <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                Email: info@vivimart.com
              </span>
            </div>
            <div className="my-4">
              <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                Return Policy
              </p>
              <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                The product is non-returnable. For a damaged, defective, expired
                or incorrect item, you can request a replacement within 24 hours
                of delivery. In case of an incorrect item, you may raise a
                replacement or return request only if the item is sealed/
                unopened/ unused and in original condition.
              </span>
            </div>
            <div className="my-4">
              <p className="text-gray-800 text-md font-semibold leading-4 mt-0 mb-2 capitalize">
                Disclaimer
              </p>
              <span className="text-gray-600 text-md leading-4 overflow-wrap-break">
                Every effort is made to maintain accuracy of all information.
                However, actual product packaging and materials may contain more
                and/or different information. It is recommended not to solely
                rely on the information presented.
              </span>
            </div>
          </div>
        </div>

        <div className="pt-6 px-4">
          <h1 className="text-black text-lg leading-8 font-bold font-sans">
            Similar Products
          </h1>
        </div>
        <div className=" flex flex-col mt-6 w-full">
          <Slider {...settings}>
            {filteredData.map((product, index) => (
              <div className="flex flex-cols w-full items-center justify-center bg-white h-full overflow-x-auto hidden-scrollbar">
                <div className="m-1 md:my-2 lg:my-2">
                  <CategoryProduct key={index} product={product} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {cartItems.length > 0 && !isCartOpen && (
          <div className="fixed left-3 right-3 flex z-[10001] bg-[#0c831f] bottom-3 text-white rounded-lg p-3.5 pl-3 pr-4 mx-auto justify-between transition-transform duration-300 transform translate-y-0 opacity-100">
            <div className="flex justify-between gap-3">
              <ShoppingCartIcon />
              <div>
                {cartItems.length} item{cartItems.length !== 1 && "s"}
              </div>
            </div>
            <div
              onClick={() => setIsCartOpen(true)}
              className="flex justify-center items-center font-sans text-[17px] leading-[22px]"
            >
              View Cart
              <ArrowRightIcon />
            </div>
          </div>
        )}
        {isCartOpen && (
          <div className="fixed inset-0 z-40 flex">
            <div
              className="fixed inset-0 bg-black opacity-50 backdrop-blur-sm"
              onClick={closeCartSidebar}
            ></div>
            <div className="ml-auto">
              <Cart
                closeCartSidebar={closeCartSidebar}
                setIsEdit={setIsEdit}
                setIsOpen={setIsOpen}
              />
            </div>
          </div>
        )}

        {isEdit && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 py-4">
            <DeliveryEdit setIsEdit={setIsEdit} setIsOpen={setIsOpen} />
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDetails;