import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import * as XLSX from "xlsx"; // Import xlsx library
import Actions from "./StoreOrderDetailsAction";
import MetaData from "../Layouts/MetaData";
import BackdropLoader from "../Layouts/BackdropLoader";
import { getAllOrders, getOrders } from "../../actions/orderAction";

const StoreOrderDetails= ({storeId}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCount, setUpdateCount] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null); // State to hold selected store data
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);

  const { subStores } = useSelector((state) => state.subStores); // Access sub-store data
  const { isDeleted, deleteError } = useSelector((state) => state.subStores);

  const { orders } = useSelector((state) => state.allOrders)
  

  useEffect(() => {
    dispatch(getAllOrders(storeId)); // Fetch all orders when component mounts
  }, [dispatch]);

  console.log("all orders data:", orders)

  const getPaymentMethodColor = (method) => {
    switch (method) {
      case 'upi':
      case 'card':
        return { color: 'green', fontWeight: 'bold' }; // Green for UPI and Card
      case 'COD':
        return { color: 'blue', fontWeight: 'bold' }; // Blue for COD
      default:
        return { color: 'black' }; // Default color
    }
  };

  const getPaymentStatusColor = (status) => {
    return status === 'Paid'
      ? { color: 'green', fontWeight: 'bold' } // Green for Paid
      : { color: 'red', fontWeight: 'bold' }; // Red for Not Paid
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "New":
        return { color: 'blue', fontWeight: 'bold' };
      case "Accepted":
        return { color: 'green', fontWeight: 'bold' };
      case "Forwarded":
        return { color: 'yellow', fontWeight: 'bold' };
      case "Dispatched":
        return { color: 'orange', fontWeight: 'bold' };
      case "Delivered":
        return { color: 'green', fontWeight: 'bold' };
      case "Canceled":
        return { color: 'red', fontWeight: 'bold' };
      default:
        return { color: 'gray', fontWeight: 'bold' };
    }
  };

  const columns = [
    {
      field: "order_id",
      headerName: "Order ID",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
    },
    {
      field: "phone_number",
      headerName: "User",
      headerAlign: "center",
      align: "center",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "store_id",
      headerName: "Store",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "total_price",
      headerName: "Total Price",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      flex: 0.1,
      renderCell: (params) => {
        const { color, fontWeight } = getPaymentStatusColor(params.row.payment_status);
        return (
          <div>
            <div>
            ₹ {params.value}
            </div>
            <div className="" style={{ color, fontWeight }}>
            ({params.row.payment_status}) {/* Display total price and payment status */}
          </div>
          </div>          
        );
      },
    },
    {
      field: "total_items",
      headerName: "Total Items",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const { color, fontWeight } = getPaymentMethodColor(params.value);
        return (
          <div style={{ color, fontWeight }}>
            {params.value.toUpperCase()} {/* Convert to uppercase */}
          </div>
        );
      },
    },
    {
      field: "order_date",
      headerName: "Order Date",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        const { color, fontWeight } = getStatusStyle(params.value);
        return (
          <div style={{ color, fontWeight }}>
            {params.value} {/* Convert to uppercase */}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 1,
      type: "number",
      sortable: false,
      renderCell: (params) => (
        <Actions id={params.row.order_id} />
      ),
    },  
  ];

  // Check if order is defined and is an array before sorting
  const rows = Array.isArray(orders) 
    ? orders
        .sort((a, b) => new Date(b.order_date) - new Date(a.order_date)) // Sort orders by date descending
        .map(order => {
          const totalQuantity = order.order_items.reduce((total, item) => total + item.quantity, 0);
          return {
            id: order.order_id,
            order_id: order.order_id,
            phone_number: order.phone_number,
            total_price: order.total_price,
            payment_status: order.payment_status,
            payment_method: order.payment_method,
            store_id: order.store_id,
            order_date: new Date(order.order_date).toLocaleDateString(),
            status: order.status,
            total_items: `${totalQuantity} item${totalQuantity !== 1 ? 's' : ''}`,
          };
        })
    : []; // Default to an empty array if order is not an array


    const getOrderCounts = (status) => {
      return orders.filter(order => order.status === status).length;
    }


    //excel downloader
      // Export data to Excel
  const exportToExcel = () => {
    const exportData = rows.map(({ id, ...rest }) => rest); // Exclude the `id` field
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");
    XLSX.writeFile(workbook, "Orders.xlsx");
  };
  

  return (
    <>
      <MetaData title="orders | Vivimart" />

      <div
        className="bg-white rounded-xl shadow-lg w-full mt-10"
        style={{ height: 470 }}
      >
        <div className="flex justify-between items-center p-4">
          <h2 className="text-lg font-bold">{storeId} Order List</h2>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200" onClick={exportToExcel}>
            Export
          </button>
        </div>
        <div className="flex justify-between items-center m-4 mb-10">
          <div className="flex-1 flex justify-center">
            <div className="flex justify-between items-center bg-gray-50 rounded-md shadow-lg hover:shadow-xl p-3 mx-2 w-full max-w-xs">
              <div className="flex items-center">
                <img
                  src="/images/Pending.png"
                  alt="Pending"
                  className="w-5 h-5"
                />
                <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                  Pending
                </h4>
              </div>
              <h2 className="text-blue-500 text-xs sm:text-xl font-bold">
                {getOrderCounts("New")}
              </h2>
            </div>
          </div>
          <div className="flex-1 flex justify-center">
            <div className="flex justify-between items-center bg-gray-50 rounded-md shadow-lg hover:shadow-xl p-3 mx-2 w-full max-w-xs">
              <div className="flex items-center">
                <img
                  src="/images/delivered.png"
                  alt="Delivered"
                  className="w-5 h-5"
                />
                <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                  Delivered
                </h4>
              </div>
              <h2 className="text-green-500 text-xs sm:text-xl font-bold">
                {getOrderCounts("Delivered")}
              </h2>
            </div>
          </div>
          <div className="flex-1 flex justify-center">
            <div className="flex justify-between items-center bg-gray-50 rounded-md shadow-lg hover:shadow-xl p-3 mx-2 w-full max-w-xs">
              <div className="flex items-center">
                <img
                  src="/images/canceled.png"
                  alt="Canceled"
                  className="w-5 h-5"
                />
                <h4 className="text-black font-medium mb-1 text-xs sm:text-sm ml-2">
                  Canceled
                </h4>
              </div>
              <h2 className="text-red-500 text-xs sm:text-xl font-bold">
                {getOrderCounts("Canceled")}
              </h2>
            </div>
          </div>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>
    </>
  );
};

export default StoreOrderDetails;
