export const UPDATE_HOLDINGS_REQUEST = "UPDATE_HOLDINGS_REQUEST";
export const UPDATE_HOLDINGS_SUCCESS = "UPDATE_HOLDINGS_SUCCESS";
export const UPDATE_HOLDINGS_FAIL = "UPDATE_HOLDINGS_FAIL";

export const UPDATE_ROYALTY_REQUEST = "UPDATE_ROYALTY_REQUEST";
export const UPDATE_ROYALTY_SUCCESS = "UPDATE_ROYALTY_SUCCESS";
export const UPDATE_ROYALTY_FAIL = "UPDATE_ROYALTY_FAIL";

export const UPDATE_WITHDRAW_LIMIT_REQUEST = "UPDATE_WITHDRAW_LIMIT_REQUEST";
export const UPDATE_WITHDRAW_LIMIT_SUCCESS = "UPDATE_WITHDRAW_LIMIT_SUCCESS";
export const UPDATE_WITHDRAW_LIMIT_FAIL = "UPDATE_WITHDRAW_LIMIT_FAIL";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL";

export const CREATE_WITHDRAW_REQUEST_REQUEST = "CREATE_WITHDRAW_REQUEST_REQUEST";
export const CREATE_WITHDRAW_REQUEST_SUCCESS = "CREATE_WITHDRAW_REQUEST_SUCCESS";
export const CREATE_WITHDRAW_REQUEST_FAIL = "CREATE_WITHDRAW_REQUEST_FAIL";

export const APPROVE_WITHDRAW_REQUEST_REQUEST = "APPROVE_WITHDRAW_REQUEST_REQUEST";
export const APPROVE_WITHDRAW_REQUEST_SUCCESS = "APPROVE_WITHDRAW_REQUEST_SUCCESS";
export const APPROVE_WITHDRAW_REQUEST_FAIL = "APPROVE_WITHDRAW_REQUEST_FAIL";

export const REJECT_WITHDRAW_REQUEST_REQUEST = "REJECT_WITHDRAW_REQUEST_REQUEST";
export const REJECT_WITHDRAW_REQUEST_SUCCESS = "REJECT_WITHDRAW_REQUEST_SUCCESS";
export const REJECT_WITHDRAW_REQUEST_FAIL = "REJECT_WITHDRAW_REQUEST_FAIL";

export const GET_WITHDRAW_REQUESTS_REQUEST = "GET_WITHDRAW_REQUESTS_REQUEST";
export const GET_WITHDRAW_REQUESTS_SUCCESS = "GET_WITHDRAW_REQUESTS_SUCCESS";
export const GET_WITHDRAW_REQUESTS_FAIL = "GET_WITHDRAW_REQUESTS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
