// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-list {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.transaction-list h2 {
    text-align: center;
    color: #333;
}

.transaction-list ul {
    list-style-type: none;
    padding: 0;
}

.transaction-list li {
    background-color: #fff;
    margin: 10px 0;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.transaction-list li:hover {
    transform: scale(1.02);
}

.transaction-list p {
    margin: 5px 0;
    color: #555;
}
`, "",{"version":3,"sources":["webpack://./src/components/Store/TransactionList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;AAC7C;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,WAAW;AACf","sourcesContent":[".transaction-list {\n    padding: 20px;\n    max-width: 800px;\n    margin: auto;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.transaction-list h2 {\n    text-align: center;\n    color: #333;\n}\n\n.transaction-list ul {\n    list-style-type: none;\n    padding: 0;\n}\n\n.transaction-list li {\n    background-color: #fff;\n    margin: 10px 0;\n    padding: 15px;\n    border-radius: 5px;\n    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s;\n}\n\n.transaction-list li:hover {\n    transform: scale(1.02);\n}\n\n.transaction-list p {\n    margin: 5px 0;\n    color: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
