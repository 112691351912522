import React, { useEffect, useState } from 'react';

import './TransactionList.css';

const TransactionList = () => {
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        // Fetch transactions from an API or state management
        const fetchTransactions = async () => {
            // Replace with actual API call
            const response = await fetch('/api/transactions');
            const data = await response.json();
            setTransactions(data);
        };

        fetchTransactions();
    }, []);

    return (
        <div className="transaction-list">
            <h2>Transaction List</h2>
            {transactions.length === 0 ? (
                <p>No transactions found.</p>
            ) : (
                <ul>
                    {transactions.map((transaction) => (
                        <li key={transaction.id}>
                            <p>{transaction.description}</p>
                            <p>Amount: ${transaction.amount}</p>
                            <p>Date: {new Date(transaction.date).toLocaleDateString()}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TransactionList;
