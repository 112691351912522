import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx"; // Import xlsx library
import MetaData from "../Layouts/MetaData";
import { getTransactionsByStoreId } from "../../actions/transactionAction";
import { useSnackbar } from "notistack";

const StoreTransactions = ({ storeId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { transactions } = useSelector((state) => state.updateSettings);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rows, setRows] = useState(transactions); 

  console.log("transaction page store id:", storeId);

  useEffect(() => {
    dispatch(getTransactionsByStoreId(storeId));
  }, [dispatch]);

  console.log("transactions:", transactions);

  useEffect(() => {
    setRows(transactions); // Update rows whenever transactions change
}, [transactions]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "holdings":
        return { color: "blue", fontWeight: "bold" };
      case "Advertisment":
        return { color: "green", fontWeight: "bold" };
      default:
        return { color: "gray", fontWeight: "bold" };
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
    },
    {
      field: "store_id",
      headerName: "Store",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        const amount = parseFloat(params.value);
        return (
          <span
            style={{ color: amount < 0 ? "red" : "green", fontWeight: "bold" }}
          >
            {amount.toLocaleString()} {/* Format the amount */}
          </span>
        );
      },
    },
    {
      field: "transaction_date",
      headerName: "Transaction Date",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        const { color, fontWeight } = getStatusStyle(params.value);
        return (
          <div style={{ color, fontWeight }}>
            {params.value} {/* Convert to uppercase */}
          </div>
        );
      },
    },
  ];

  // Export data to Excel
  const exportFilteredToExcel = () => {
    const exportData = rows.map(({ id, ...rest }) => rest); // Exclude the `id` field
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Transactions");
    XLSX.writeFile(workbook, "Filtered_Transactions.xlsx");
  };

  const filterByDateRange = () => {
    if (!startDate || !endDate) {
      enqueueSnackbar("Please select both start and end date", {
        variation: "warning",
      });
    }

    const filteredRows = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.created_at);
      return (
        transactionDate >= new Date(startDate) &&
        transactionDate <= new Date(endDate)
      );
    });

    setRows(filteredRows);
  };

  return (
    <>
      <MetaData title="orders | Vivimart" />

      <div
        className="bg-white rounded-xl shadow-lg w-full mt-10"
        style={{ height: 470 }}
      >
        <div className="flex justify-between items-center p-4">
          <h2 className="text-lg font-bold">{storeId} Transactions</h2>
          <div>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="border rounded p-2 mr-2"
            />
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="border rounded p-2 mr-2"
            />
            <button
              onClick={filterByDateRange}
              className="bg-blue-500 text-white rounded px-4 py-2"
            >
              Filter
            </button>
            <button
              onClick={exportFilteredToExcel}
              className="bg-green-500 text-white rounded px-4 py-2 ml-2"
            >
              Export to Excel
            </button>
          </div>
        </div>

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>
    </>
  );
};

export default StoreTransactions;
