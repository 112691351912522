import { 
    UPDATE_HOLDINGS_REQUEST, 
    UPDATE_HOLDINGS_SUCCESS, 
    UPDATE_HOLDINGS_FAIL,
    UPDATE_WITHDRAW_LIMIT_REQUEST, 
    UPDATE_WITHDRAW_LIMIT_SUCCESS, 
    UPDATE_WITHDRAW_LIMIT_FAIL,
    CLEAR_ERRORS, 
    UPDATE_ROYALTY_REQUEST,
    UPDATE_ROYALTY_FAIL,
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAIL,
    UPDATE_ROYALTY_SUCCESS,
    CREATE_WITHDRAW_REQUEST_REQUEST,
    CREATE_WITHDRAW_REQUEST_SUCCESS,
    CREATE_WITHDRAW_REQUEST_FAIL,
    APPROVE_WITHDRAW_REQUEST_REQUEST,
    APPROVE_WITHDRAW_REQUEST_SUCCESS,
    APPROVE_WITHDRAW_REQUEST_FAIL,
    REJECT_WITHDRAW_REQUEST_REQUEST,
    REJECT_WITHDRAW_REQUEST_SUCCESS,
    REJECT_WITHDRAW_REQUEST_FAIL,
    GET_WITHDRAW_REQUESTS_REQUEST, 
    GET_WITHDRAW_REQUESTS_SUCCESS, 
    GET_WITHDRAW_REQUESTS_FAIL,
} from "../constants/transactionConstants";

const initialState = {
    holdingsUpdateSuccess: false,
    withdrawLimitUpdateSuccess: false,
    royaltyUpdateSuccess: false,
    withdrawRequestSuccess: false,
    transactions: [],
    withdrawRequests: [],
    loading: false,
    error: null,
};

export const updateSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_HOLDINGS_REQUEST:
        case UPDATE_WITHDRAW_LIMIT_REQUEST:
        case UPDATE_ROYALTY_REQUEST:
        case CREATE_WITHDRAW_REQUEST_REQUEST:
        case APPROVE_WITHDRAW_REQUEST_REQUEST:
        case REJECT_WITHDRAW_REQUEST_REQUEST:
        case GET_TRANSACTIONS_REQUEST:
        case GET_WITHDRAW_REQUESTS_REQUEST:
            return { ...state, loading: true };
        
        case UPDATE_HOLDINGS_SUCCESS:
            return { ...state, loading: false, holdingsUpdateSuccess: true };
        
        case UPDATE_WITHDRAW_LIMIT_SUCCESS:
            return { ...state, loading: false, withdrawLimitUpdateSuccess: true };

        case UPDATE_ROYALTY_SUCCESS:
            return { ...state, loading: false, royaltyUpdateSuccess: true };

        case CREATE_WITHDRAW_REQUEST_SUCCESS:
            return { ...state, loading: false, withdrawRequestSuccess: true };

        case APPROVE_WITHDRAW_REQUEST_SUCCESS:
        case REJECT_WITHDRAW_REQUEST_SUCCESS:
            return { ...state, loading: false, withdrawRequestSuccess: true };

        case GET_TRANSACTIONS_SUCCESS:
            return { ...state, loading: false, transactions: action.payload };
        case GET_WITHDRAW_REQUESTS_SUCCESS:
            return { ...state, loading: false, withdrawRequests: action.payload };

        case UPDATE_HOLDINGS_FAIL:
        case UPDATE_WITHDRAW_LIMIT_FAIL:
        case UPDATE_ROYALTY_FAIL:
        case CREATE_WITHDRAW_REQUEST_FAIL:
        case APPROVE_WITHDRAW_REQUEST_FAIL:
        case REJECT_WITHDRAW_REQUEST_FAIL:
        case GET_TRANSACTIONS_FAIL:
        case GET_WITHDRAW_REQUESTS_FAIL:
            return { ...state, loading: false, error: action.payload };
 
        case CLEAR_ERRORS:
            return { ...state, error: null };
        
        default:
            return state;
    }
};
