import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDwFWZ_gV5MRMSgHq9xEmnrCCrEltjdl4s",
    authDomain: "vivimartuser.firebaseapp.com",
    projectId: "vivimartuser",
    storageBucket: "vivimartuser.appspot.com",
    messagingSenderId: "978230642962",
    appId: "1:978230642962:web:e8d97ff4228aa5ab740bcb",
    measurementId: "G-24PKCSSS02",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const messaging = getMessaging(app);

export { auth, messaging };
