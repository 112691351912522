import React, { useState } from 'react';
import { useParams } from 'react-router';
import StoreOverview from './StoreOverview';
import StoreOrderDetails from './StoreOrderDetails';
import { useDispatch, useSelector } from 'react-redux';
import StoreTransactions from './StoreTransactions';
import StoreWithdrawRequest from './StoreWithdrawRequest';

const StoreDetails = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('store-overview');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'store-overview':
        return <StoreOverview storeId={id} />;
      case 'orders':
        return <StoreOrderDetails storeId={id} />;
      case 'transactions':
        return <StoreTransactions storeId={id} />;
      case 'requests':
        return <StoreWithdrawRequest storeId={id} />;
      default:
        return <StoreOverview storeId={id} />;
    }
  };

  return (
    <div className="flex p-4 w-full">
      <div className="items-center gap-2 w-[100%]">
        <h1 className="text-2xl font-semibold">Store Details</h1>
        <div className="flex items-center mt-4">
          <ul className="flex items-center gap-10">
            <li
              className={`text-gray-600 ${activeTab === 'store-overview' ? 'text-gray-900 border-b-2 border-gray-900' : ''}`}
              onClick={() => setActiveTab('store-overview')}
            >
              Store Overview
            </li>
            <li
              className={`text-gray-600 ${activeTab === 'orders' ? 'text-gray-900 border-b-2 border-gray-900' : ''}`}
              onClick={() => setActiveTab('orders')}
            >
              Orders
            </li>
            <li
              className={`text-gray-600 ${activeTab === 'transactions' ? 'text-gray-900 border-b-2 border-gray-900' : ''}`}
              onClick={() => setActiveTab('transactions')}
            >
              Transactions
            </li>
            <li
              className={`text-gray-600 ${activeTab === 'transactions' ? 'text-gray-900 border-b-2 border-gray-900' : ''}`}
              onClick={() => setActiveTab('requests')}
            >
              Withdraw Requests
            </li>
          </ul>
        </div>
        <div className='w-full'>
        {renderTabContent()}
        </div>        
      </div>
    </div>
  );
};

export default StoreDetails;