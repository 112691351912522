import { 
    UPDATE_HOLDINGS_REQUEST, 
    UPDATE_HOLDINGS_SUCCESS, 
    UPDATE_HOLDINGS_FAIL,
    UPDATE_WITHDRAW_LIMIT_REQUEST, 
    UPDATE_WITHDRAW_LIMIT_SUCCESS, 
    UPDATE_WITHDRAW_LIMIT_FAIL,
    CLEAR_ERRORS, 
    UPDATE_ROYALTY_FAIL,
    UPDATE_ROYALTY_REQUEST,
    UPDATE_ROYALTY_SUCCESS,
    GET_TRANSACTIONS_REQUEST,
    GET_TRANSACTIONS_SUCCESS,
    GET_TRANSACTIONS_FAIL,
    CREATE_WITHDRAW_REQUEST_REQUEST,
    CREATE_WITHDRAW_REQUEST_SUCCESS,
    CREATE_WITHDRAW_REQUEST_FAIL,
    APPROVE_WITHDRAW_REQUEST_REQUEST,
    APPROVE_WITHDRAW_REQUEST_SUCCESS,
    APPROVE_WITHDRAW_REQUEST_FAIL,
    REJECT_WITHDRAW_REQUEST_REQUEST,
    REJECT_WITHDRAW_REQUEST_SUCCESS,
    REJECT_WITHDRAW_REQUEST_FAIL,
    GET_WITHDRAW_REQUESTS_REQUEST, 
    GET_WITHDRAW_REQUESTS_SUCCESS, 
    GET_WITHDRAW_REQUESTS_FAIL,
} from "../constants/transactionConstants";
import axiosInstance from "../utils/api";

// Update holdings
export const updateHoldings = (data) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_HOLDINGS_REQUEST });
        const response = await axiosInstance.put('/transaction/update-holdings', data);
        dispatch({ type: UPDATE_HOLDINGS_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({
            type: UPDATE_HOLDINGS_FAIL,
            payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
        });
    }
};

export const updateRoyalty = (id, royalty) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ROYALTY_REQUEST });
        const { data } = await axiosInstance.put("/transaction/update-royalty", { id, royalty });
        dispatch({ type: UPDATE_ROYALTY_SUCCESS, payload: data });
    } catch (error) {
    dispatch({
        type: UPDATE_ROYALTY_FAIL,
        payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
    })
        
    }
}

export const getTransactionsByStoreId = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_TRANSACTIONS_REQUEST })
        const { data } = await axiosInstance.get(`/transaction/${id}`)
        dispatch({ type: GET_TRANSACTIONS_SUCCESS, payload: data })
    } catch (error) {
        dispatch({
            type: GET_TRANSACTIONS_FAIL,
            payload: error.response && error.response.data.error 
                    ? error.response.data.error 
                    : error.message,
        })        
    }
}
 
// Update withdraw limit
export const updateWithdrawLimit = (id, withdrawLimit) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_WITHDRAW_LIMIT_REQUEST });
        const { data } = await axiosInstance.put("/transaction/update-withdraw-limit", { id, withdrawLimit });
        dispatch({ type: UPDATE_WITHDRAW_LIMIT_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: UPDATE_WITHDRAW_LIMIT_FAIL,
            payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
        });
    }
};

// Create withdraw request
export const createWithdrawRequest = (data) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_WITHDRAW_REQUEST_REQUEST });
        const response = await axiosInstance.post('/transaction/create-withdraw-request', data);
        dispatch({ type: CREATE_WITHDRAW_REQUEST_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({
            type: CREATE_WITHDRAW_REQUEST_FAIL,
            payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
        });
    }
};

// Approve withdraw request
export const approveWithdrawRequest = (requestId) => async (dispatch) => {
    try {
        dispatch({ type: APPROVE_WITHDRAW_REQUEST_REQUEST });
        const response = await axiosInstance.put('/transaction/approve-withdraw-request', { requestId });
        dispatch({ type: APPROVE_WITHDRAW_REQUEST_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({
            type: APPROVE_WITHDRAW_REQUEST_FAIL,
            payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
        });
    }
};

// Reject withdraw request
export const rejectWithdrawRequest = (requestId) => async (dispatch) => {
    try {
        dispatch({ type: REJECT_WITHDRAW_REQUEST_REQUEST });
        const response = await axiosInstance.put('/transaction/reject-withdraw-request', { requestId });
        dispatch({ type: REJECT_WITHDRAW_REQUEST_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({
            type: REJECT_WITHDRAW_REQUEST_FAIL,
            payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
        });
    }
};

export const getWithdrawRequestsByStoreId = (storeId) => async (dispatch) => {
    try {
        dispatch({ type: GET_WITHDRAW_REQUESTS_REQUEST });
        const { data } = await axiosInstance.get(`/transaction/withdraw-requests/${storeId}`);
        dispatch({ type: GET_WITHDRAW_REQUESTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: GET_WITHDRAW_REQUESTS_FAIL,
            payload: error.response && error.response.data.error 
                ? error.response.data.error 
                : error.message,
        });
    }
};

// Clear errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};
