import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx"; // Import xlsx library
import MetaData from "../Layouts/MetaData";
import { getWithdrawRequestsByStoreId } from "../../actions/transactionAction";
import { useSnackbar } from "notistack";
import StoreWithdrawRequestModal from "./StoreWithdrawRequestModal";

const StoreWithdrawRequest = ({ storeId }) => {
  const dispatch = useDispatch();
  const { withdrawRequests } = useSelector((state) => state.updateSettings);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [selectedRequest, setSelectedRequest] = useState(null); // State to hold selected request details

  console.log("transaction page store id:", storeId);

  useEffect(() => {
    dispatch(getWithdrawRequestsByStoreId(storeId));
  }, [dispatch]);

  console.log("withdrawRequests:", withdrawRequests);

  const getStatusStyle = (status) => {
    switch (status) {
      case "pending":
        return { color: "blue", fontWeight: "bold" };
      case "approved":
        return { color: "green", fontWeight: "bold" };
    case "rejected":
        return { color: "green", fontWeight: "bold" };
      default:
        return { color: "gray", fontWeight: "bold" };
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Request ID",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => (
        <span
          style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
          onClick={() => {
            setSelectedRequest(params.row); // Set the selected request
            setIsModalOpen(true); // Open the modal
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "store_id",
      headerName: "Store",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "date",
      headerName: "Transaction Date",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      flex: 0.1,
      renderCell: (params) => {
        const { color, fontWeight } = getStatusStyle(params.value);
        return (
          <div style={{ color, fontWeight }}>
            {params.value} {/* Convert to uppercase */}
          </div>
        );
      },
    },
  ];

  // Check if order is defined and is an array before sorting
  const rows = Array.isArray(withdrawRequests) 
    ? withdrawRequests
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort orders by date descending
        .map(item => {
          return {
            id: item.id,
            amount: item.amount,
            store_id: item.store_id,
            date: new Date(item.created_at).toLocaleDateString(),
            status: item.status,
          };
        })
    : []; // Default to an empty array if order is not an array

  // Export data to Excel
  const exportFilteredToExcel = () => {
    const exportData = rows.map(({ id, ...rest }) => rest); // Exclude the `id` field
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered withdrawRequests");
    XLSX.writeFile(workbook, "withdrawRequests.xlsx");
  };

 

  return (
    <>
      <MetaData title="orders | Vivimart" />

      <div
        className="bg-white rounded-xl shadow-lg w-full mt-10"
        style={{ height: 470 }}
      >
        <div className="flex justify-between items-center p-4">
          <h2 className="text-lg font-bold">{storeId} Withdraw Request</h2>          
        </div>

        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          disableSelectIconOnClick
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </div>
      {/* Modal for displaying request details */}
      <StoreWithdrawRequestModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        requestDetails={selectedRequest} 
      />
    </>
  );
};

export default StoreWithdrawRequest;
